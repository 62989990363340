import React from 'react';
import { Link } from 'gatsby';
import Button from '../../layout/buttons';

function Position({ title, deadline, to, type }) {
  if (deadline.date === null) {
    var setDeadline = 'Snarest';
  } else {
    var setDeadline = deadline.date;
  }

  return (
    <article className='position-single'>
      <Link to={to}>
        <h4>{title}</h4>
        <p>Søknadsfrist: {setDeadline}</p>
        <Button>
          Les mer <span className='icon'>&rarr;</span>
        </Button>
      </Link>
    </article>
  );
}

export default Position;
