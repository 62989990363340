import React from 'react';
import Layout from '../components/layout/layout';
import { graphql } from 'gatsby';

import { TopSection } from '../components/topsection';
import OpenPositions from '../components/positions/allPositions';
import Wrapper from '../components/layout/wrapper';
import SEO from '../components/seo'

const StillingerPage = ({ data }) => (
  <Layout>
    <SEO title={'Bli en av oss'} description={data.wpPage.seo.metaDesc}/>
    <TopSection title='Bli en av oss' color='green' />
    <Wrapper width={1024} center>
      <div dangerouslySetInnerHTML={{ __html: data.wpPage.content.main }}></div>
      <OpenPositions />
      <div
        className='open-positions'
        dangerouslySetInnerHTML={{ __html: data.wpPage.content.secondary }}
      ></div>
    </Wrapper>
  </Layout>
);

export default StillingerPage;

export const CONTENTQUERY = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoyNzk=" }) {
      content: positionsPageContent {
        main: maincontent
        secondary: secondarycontent
      }
    seo {
      metaDesc
    }
    }
  }
`;
