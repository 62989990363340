import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Position from './single';
import './position.scss';

function OpenPositions() {
  const data = useStaticQuery(graphql`
    query Positions {
      acf: wpPage(id: { eq: "cG9zdDoyNzk=" }) {
        content: positionsPageContent {
          nopos: nopositionscontent
        }
      }
      allPositions: allWpOpenPosition {
        totalCount
        edges {
          node {
            title
            id
            slug
            content: postContent {
              positiontype
              location: sted
              type: positiontype
              deadline {
                date: dato
                type
              }
              title: positiontitle
            }
          }
        }
      }
    }
  `);

  return (
    <section id='allPositions'>
      {
        // If no positions are available, show text instead
        (data.totalCount = 0 ? (
          <p
            className='no-available-positions'
            dangerouslySetInnerHTML={{ __html: data.acf.content.nopos }}
          ></p>
        ) : (
          data.allPositions.edges.map((post) => (
            <Position
              key={post.node.id}
              title={post.node.title}
              position={post.node.content.title}
              deadline={post.node.content.deadline}
              to={post.node.slug}
              type={post.node.content.type}
            />
          ))
        ))
      }
    </section>
  );
}

export default OpenPositions;
